import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileScreenButton } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";

export const Navigation = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 960) {
      setIsMobile(true);
    }
  }, []);
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          {isMobile ? (
            <Typography variant="h6">
          <a className="navbar-brand page-scroll" href="#page-top">
          <FontAwesomeIcon
              icon={faMobileScreenButton} // Use the correct icon name
              size="sm"
              style={{ color: "#000000" }}
            />{" "} VDM
          </a>
          </Typography>
          ) : (
            <a className="navbar-brand page-scroll" href="#page-top">
            <FontAwesomeIcon
                icon={faMobileScreenButton} // Use the correct icon name
                size="sm"
                style={{ color: "#000000" }}
              />{" "} ValiantMarketing.US
            </a>
          )
          
          }
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#features" className="page-scroll">
                <Typography variant="h5"><b>Features</b></Typography>
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
              <Typography variant="h5"><b> About</b></Typography>
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
              <Typography variant="h5"><b>Services</b></Typography>
              </a>
            </li>
            {/* <li>
              <a href="#portfolio" className="page-scroll">
                Gallery
              </a>
            </li> */}
            <li>
              <a href="#testimonials" className="page-scroll">
              <Typography variant="h5"><b>Testimonials</b></Typography>
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll">
              <Typography variant="h5"><b>Team</b></Typography>
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
              <Typography variant="h5"><b>Contact</b></Typography>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
