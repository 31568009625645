import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileScreenButton } from "@fortawesome/free-solid-svg-icons";

export const Header = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 960) {
      setIsMobile(true);
    }
  }, []);
  return (
    <header id="header">
      {isMobile ? (
         <div className="intro">
         <div className="overlay">
           <div className="container">
             <div className="row">
               <div className="col-md-8 col-md-offset-2 intro-text">
                  <h2 className="glowing-text pop-out">
                  <FontAwesomeIcon
              icon={faMobileScreenButton} // Use the correct icon name
              size="sm"
              style={{ color: "#white" }}
            />{" "} Valiant Digital Marketing
                    </h2>
                 <a
                   href="#contact"
                   className="btn btn-custom btn-lg page-scroll"
                 >
                   <Typography variant="h3" className="glowing-text">
                     Get in Touch
                   </Typography>
                 </a>{" "}
               </div>
             </div>
           </div>
         </div>
       </div>
      ) : (
        <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
              <h1 className="glowing-text pop-out">
                  <FontAwesomeIcon
              icon={faMobileScreenButton} // Use the correct icon name
              size="sm"
              style={{ color: "#white" }}
            />{" "} Valiant Digital Marketing
                    </h1>
                <Typography variant="h2" color="text.secondary">
                  <p
                    className="glowing-text pop-out"
                    style={{
                      marginTop: "50px",
                      position: "relative",
                      color: "#fff",
                    }}
                  >
                    <b>{props.data ? props.data.paragraph : "Loading"}</b>
                    <span
                      className="text-background"
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        right: "0",
                        bottom: "0",
                        zIndex: "-1",
                        borderRadius: "10px",
                      }}
                    ></span>
                  </p>
                </Typography>
                <a
                  href="#contact"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  <Typography variant="h3" className="glowing-text">
                    Get in Touch
                  </Typography>
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </header>
  );
};
