import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
// import { Features } from "./components/features"; 
import { About } from "./components/about";
import { Services } from "./components/services";
// import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { ThemeProvider, createTheme, Box } from "@mui/material";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const theme = createTheme({
    typography: {
      fontFamily: [
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        "sans-serif",
      ].join(","),
    },
  });

  // hide header on mobile
  // const [isMobile, setIsMobile] = useState(false);
  // useEffect(() => {
  //   if (window.innerWidth < 960) {
  //     setIsMobile(true);
  //   }
  // }, []);

  return (
    <div>
      <ThemeProvider theme={theme}>
      <Box sx={{ width: 1 }}> 
        <Navigation />
        {/* {isMobile ? null : <Header data={landingPageData.Header} />}      */}
        <Header data={landingPageData.Header} />
        {/* {isMobile ? null : <Features data={landingPageData.Features} />}     // Features     */}
        <About data={landingPageData.About} />
        <Services data={landingPageData.Services} />
        {/* <Gallery data={landingPageData.Gallery} /> */}
        <Testimonials data={landingPageData.Testimonials} />
        <Team data={landingPageData.Team} />
        <Contact data={landingPageData.Contact} />
      </Box>
      </ThemeProvider>
    </div>
  );
};

export default App;
