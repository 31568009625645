import React from "react";
import { useState } from "react";
// import emailjs from "emailjs-com";
import emailjs from "@emailjs/browser";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";

const initialState = {
  name: "",
  email: "",
  phone: "", // Add phone field
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, phone, message }, setState] = useState(initialState);
  const [openDialog, setOpenDialog] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(name, email, phone, message);
    console.log(e.target, "e.target")
    emailjs
      .sendForm(
        "service_9xteq7f", // "YOUR_SERVICE_ID
        "template_yl9k69c", // "YOUR_TEMPLATE_ID
        e.target,
        "RbyUVrn2XpayXESAh" // React emailjs user ID
      )
      .then(
        (result) => {
          console.log(result.text);
          clearState();
          setOpenDialog(true);
        },
        (error) => {
          console.log(error.text);
          setOpenDialog(true);
        }
      );
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <Typography variant="h3" color="text.secondary">
                  Please fill out the form below to send us an email, and we
                  will get back to you as soon as possible.
                </Typography>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="tel" // Use type "tel" for phone numbers
                        id="phone" // Add id for the phone input
                        name="phone" // Add name for the phone input
                        className="form-control"
                        placeholder="Phone Number" // Adjust the placeholder text
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h2>Contact Info</h2>
            </div>
            <Typography variant="h5" color="text.secondary">
            <div className="contact-item">
              <Typography
                variant="h4"
                color="white"
              >
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                <b>{props.data ? props.data.email : "loading"}</b>
              </Typography>
              <h3>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                <b>{props.data ? props.data.phone : "loading"}</b>
              </h3>
            </div>
            </Typography>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href="https://www.instagram.com/valiant.marketing/">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    {/* <a> */}
                      <i className="fa fa-twitter"></i>
                    {/* </a> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; 2023 Valiant Digital Marketing </p>
        </div>
      </div>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle
          style={{
            textAlign: "center",
            background: openDialog
              ? "linear-gradient(135deg, #75cafe, #4c90e2)"
              : "linear-gradient(135deg, #ffb3b3, #fd7979)",
          }}
        >
          {openDialog ? <h4>Message Sent</h4> : <h4>Try Again</h4>}
        </DialogTitle>
        <DialogContent>
          <h4>
            {openDialog
              ? "Your message has been sent successfully! We will be in contact with you shortly."
              : "There was an error. Please try again."}
          </h4>
        </DialogContent>
      </Dialog>
    </div>
  );
};
