import { Typography } from "@mui/material";
import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <Typography variant="h3" color="text.secondary">
            At Valiant Marketing, we understand that every client is
            distinctive. Our services are meticulously customized to cater to
            your individual requirements, assuring your brand's triumph in the
            digital sphere. With a specialized focus on social media marketing,
            we excel in crafting personalized strategies to elevate your brand's
            online presence and engage your target audience.
          </Typography>
          
        </div>
        <div className="row space">
          <Typography variant="h2">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                  <Typography variant="h3" color="text.secondary">{d.name}</Typography>
                    <h5 color="white" className="glowing-text">{d.text}</h5>
                  </div>
                </div>
              ))
            : "loading"}
            </Typography>
        </div>
      </div>
    </div>
  );
};
